import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

import { Store } from "@ngrx/store";
import { initialState, State } from 'src/app/models/patient';

import * as fromRoot from '../../store/reducers';

import { ConfigService } from 'src/app/services/config.service';

import { AppointmentRequest } from 'src/app/models/booking';
import { StaffService } from 'src/app/services/staff.service';
import { take } from 'rxjs';



@Component({
    selector: 'app-appointment-info',
    templateUrl: './appointment-info.component.html',
    styleUrls: ['./appointment-info.component.scss'],
    standalone: false
})

export class AppointmentInfoComponent implements OnInit {

    @Input() appointment;

    public loggedIn: boolean = false;
    public patient: State = initialState;
    public staff: any;
    public activeAppointment: any;
    public clinic: any;
    public emailClinic: string;
    public appointmentSaved = false;

    public requestedAppointment: AppointmentRequest | undefined;

    constructor(private store: Store<fromRoot.State>,
        private config: ConfigService,
        private staffService: StaffService,
    ) {




        this.config.getStoredClinicDetails().subscribe((clinic: any) => this.clinic = clinic);
    }


    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges) {
        if (this.appointment) {
            this.staffService.staffFromIdx(this.appointment.staffIdx).pipe(take(1)).subscribe(res => {
                console.log("STAFF=", this.appointment.staffIdx, res);
                this.staff = res;
            });
        }

    }
}