
@if (appointment){
    
    <app-appointment-info [appointment]="appointment"></app-appointment-info>
    <div class="py-3 d-flex justify-content-around">
        
        <button mat-raised-button color="primary" (click)="downloadAppointment()">
            <span class="material-icons">event</span>&nbsp;Download Appointment
        </button>
            <a mat-button href='/'>Book another</a>

    </div>
}


